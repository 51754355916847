export const environment = {
  production: false,
  baseURL: 'https://hank-edu-admin-portal-dev.azure-api.net',
  firebaseApiUrl: 'https://identitytoolkit.googleapis.com/v1/',
  firebase: {
    apiKey: 'AIzaSyDgEZDuOg28yBILa_XHSdStex6qE2Tyqtg',
    authDomain: 'hank-edu-dev.firebaseapp.com',
    projectId: 'hank-edu-dev',
    appId: '1:31612925636:web:0143dbb02c2d20a9893b91'
  }
};
