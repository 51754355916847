import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorTypes} from '@core/common';

@Injectable({providedIn: 'root'})
export class ErrorMessageService {

  static getErrorWithMessage(error: HttpErrorResponse | string): HttpErrorResponse | string {

    if (typeof error === 'string' || error instanceof String) {
      return ErrorMessageService.getHttpErrorMessageByCode(error as any);
    }

    if ((!error.hasOwnProperty('error') || !error.error) || !error.error.hasOwnProperty('code')) {
      return error;
    }

    error.error.message = ErrorMessageService.getHttpErrorMessageByCode(error.error.code);
    return error;
  }

  static getHttpErrorMessageByCode(code: string): string {
    switch (code) {
      case ErrorTypes.unknown : {
        return 'Something wrong';
      }

      case ErrorTypes.disbursementFileDuplicate : {
        return 'Upload Failed; The Disbursement Data file with this name has already been uploaded.';
      }
      case ErrorTypes.demographicFileDuplicate : {
        return 'Upload Failed; This Demographic File has already been uploaded.';
      }

      case ErrorTypes.disbursementDataDuplicate : {
        return 'The Disbursement File contains the duplicated data';
      }

      case ErrorTypes.demographicDataImportFailed : {
        return 'Import Failed';
      }

      case ErrorTypes.fileInvalid : {
        return 'Upload Failed; Invalid File Format';
      }

      case ErrorTypes.disbursementFileNameInvalid: {
        return 'Upload Failed; Invalid File Name Format';
      }

      case ErrorTypes.disbursementFileHeaderInvalid: {
        return 'Upload Failed; There is an invalid header in the File you are trying to upload.';
      }

      case ErrorTypes.disbursementFileInvalidFormat: {
        return 'Invalid File Format';
      }

      case ErrorTypes.disbursementFileRequiredDataMissing: {
        return 'Upload Failed; Required Data is Missing';
      }

      case ErrorTypes.disbursementTransactionNotFound: {
        return 'Disbursement Transaction Not Found';
      }

      case ErrorTypes.disbursementAmountExceedsBalance: {
        return 'Not enough funds on your escrow account.';
      }

      case ErrorTypes.studentWithTheSameEmailAlreadyExists: {
        return 'Student With The Same Email Already Exists';
      }

      default: {
        if (code) {
          return 'Something wrong:' + ' ' + code.split(/([A-Z]?[^A-Z]*)/g).join(' ');
        } else {
          return 'Something wrong';
        }
      }
    }
  }
}

