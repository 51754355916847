import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreDocument,} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import {TokenEmailAuthCredential} from '@core/services/auth/TokenEmailAuthCredential';
import {User} from '@core/services/user';
import {environment} from '@environments/environment';
import firebase from 'firebase/compat/app';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthService {
  pending$: Observable<boolean>;
  error$: Observable<HttpErrorResponse>;
  userData: any; // Save logged in user data
  private _pending$ = new BehaviorSubject<boolean>(false);
  private _error$ = new BehaviorSubject<any>(null);

  constructor(
    private _firestore: AngularFirestore,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,// NgZone service to remove outside scope warning
  ) {
    this.pending$ = this._pending$.asObservable();
    this.error$ = this._error$.asObservable();

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
        this.router.navigate(['credit-disbursement']);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  // Sign in with email/password
  signIn(email: string, password: string) {
    this._pending$.next(true);
    this._error$.next(null);
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this._pending$.next(false);
        this.setUserData(result.user);
      })
      .catch(() => {
        this._error$.next('Login failed; Invalid username or password.');
        this._pending$.next(false);
      });
  }

  // Reset Forgot password
  forgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null;
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['auth/sign-in']);
    });
  }

  getRefreshToken() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const user = JSON.parse(localStorage.getItem('user')!);
    return user?.stsTokenManager?.refreshToken;
  }

  checkActionCode(code: string): Promise<firebase.auth.ActionCodeInfo> {
    return this.afAuth.checkActionCode(code);
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return this.afAuth.confirmPasswordReset(code, newPassword);
  }

  /**
   * Manually get refresh token and get new set of firebase tokens
   * */
  async refreshToken(token) {
    const response = await fetch(`${environment.firebaseApiUrl}token?key=${environment.firebase.apiKey}`, {
      method: 'POST',
      body: JSON.stringify({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        grant_type: 'refresh_token',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        refresh_token: token,
      }),
      headers: {
        'x-firebase-gmpid': environment.firebase.appId
      }
    });
    return response.json();
  }

  async getFirebaseAccount(idToken) {
    const responseRaw = await fetch(`${environment.firebaseApiUrl}accounts:lookup?key=${environment.firebase.apiKey}`, {
      method: 'POST',
      body: JSON.stringify({
        idToken
      }),
      headers: {
        'x-firebase-gmpid': environment.firebase.appId
      }
    });
    const response = await responseRaw.json();
    return response.users;
  }

  /**
   * Accept firebase refresh_token, get all required data and authorize the user
   * */
  public async signInWithToken(token: string) {
    const tokens = await this.refreshToken(token);
    const users = await this.getFirebaseAccount(tokens.id_token);
    const u = users[0];
    const idTokenResponse = {
      displayName: u.displayName ?? '',
      email: u.email,
      expiresIn: tokens.expires_in,
      idToken: tokens.id_token,
      kind: 'identitytoolkit#VerifyPasswordResponse',
      localId: u.localId,
      refreshToken: tokens.refresh_token,
      registered: true
    };

    const credential = new TokenEmailAuthCredential();
    credential.setTokenResponse(idTokenResponse);

    const signInCred = await firebase.auth().signInWithCredential(credential);
    this.setUserData(signInCred.user);

  }

}
