export enum ErrorTypes {
  unknown = 'error.unknown',
  fileInvalid = 'error.demographicData.fileInvalid',
  disbursementFileDuplicate = 'error.disbursementFile.duplicate',
  demographicFileDuplicate = 'error.demographicFile.duplicate',
  disbursementDataDuplicate = 'error.disbursementData.duplicate',
  disbursementFileNameInvalid = 'error.disbursementFile.nameInvalid',
  disbursementFileHeaderInvalid = 'error.disbursementFile.headerInvalid',
  disbursementFileInvalidFormat = 'error.disbursementFile.invalidFormat',
  disbursementFileRequiredDataMissing = 'error.disbursementFile.requiredDataMissing',
  demographicDataImportFailed = 'error.demographicData.importFailed',
  disbursementAmountExceedsBalance = 'error.disbursement.amountExceedsBalance',
  disbursementTransactionNotFound = 'error.disbursement.berkeleyTransactionNotFound',
  studentWithTheSameEmailAlreadyExists = 'error.studentDirectory.studentWithTheSameEmailAlreadyExists',
}
