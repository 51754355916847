import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AuthService} from '@core/services/auth';
import {AuthTokenType} from '@core/services/auth/auth.types';
import {catchError, Observable, throwError} from 'rxjs';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  authService = this._injector.get(AuthService);
  firebaseAuthService = this._injector.get(AngularFireAuth);

  constructor(private _injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addTokens(req)).pipe(
      catchError(error => this.processError(req, next, error)));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    return this.logoutUser(error);
  }

  private processError(req: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch (error?.status) {
        case 401: {
          return this.handle401Error(req, next, error);
        }
        default:
          return throwError(() => error);
      }
    } else {
      return throwError(() => error);
    }
  }

  private addTokens(req: HttpRequest<any>): HttpRequest<any> {
    const access = JSON.parse(localStorage.getItem('user'))?.stsTokenManager?.accessToken;
    if (access) {

      req = req.clone({
        setHeaders: {
          [AuthTokenType.access]: `Bearer ${access}`,
        }
      });
    }

    return req;
  }

  private logoutUser(error): Observable<never> {
    this.authService.signOut();
    return throwError(() => error);
  }

}
